import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import Common from '../data/constants/common';
import { setGlobalError } from '../redux/slices/appSlice';
import { UseMethodTransferData } from 'hooks/useMethodList';

export const Utils = {
  rupiahWithoutDecimal: (number: number): string => {
    return new Intl.NumberFormat('id-ID', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(Math.floor(number));
  },

  balanceDecimal: (number: number): string => {
    return (number % 1).toFixed(2).substring(2);
  },

  toTitleCase: (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  },

  delay: (ms?: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  getFirstDayOfMonth: (year: number, month: number) => {
    const date = new Date(year, month, 1);
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${
      date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }/${date.getFullYear()}`;
  },

  getLastDayOfMonth: (year: number, month: number) => {
    const date = new Date(year, month + 1, 0);
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${
      date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }/${date.getFullYear()}`;
  },

  getFirstDayHistoryOfMonth: (year: number, month: number) => {
    const date = new Date(year, month, 1);

    const padZero = (num: number) => (num < 10 ? '0' + num : num);

    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
      date.getDate()
    )}`;
    const time = `00:00:00`;

    return `${formattedDate} ${time}`;
  },

  getLastDayHistoryOfMonth: (year: number, month: number) => {
    const date = new Date(year, month + 1, 0);

    const padZero = (num: number) => (num < 10 ? '0' + num : num);

    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
      date.getDate()
    )}`;
    const time = `23:59:59`;

    return `${formattedDate} ${time}`;
  },

  convertToFormatID: (date: string, shortMonth?: boolean) => {
    const stringDate = date.split('-');
    return `${stringDate[2]} ${
      shortMonth
        ? Common.shortMonths[Number(stringDate[1]) - 1]
        : Common.months[Number(stringDate[1]) - 1]
    } ${stringDate[0]}`;
  },

  convertToFormatIDWith3Digit: (date: string, shortMonth?: boolean) => {
    const stringDate = date.split('-');
    const digitString = `${
      shortMonth
        ? Common.shortMonths[Number(stringDate[1]) - 1]
        : Common.months[Number(stringDate[1]) - 1]
    }`;
    return `${stringDate[2]} ${digitString.substring(0, 3)} ${stringDate[0]}`;
  },

  convertFormatFullDateTime: (dateTime: string, shortMonth?: boolean) => {
    const date = new Date(dateTime);
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    const month = shortMonth ? Common.shortMonths[date.getMonth()] : Common.months[date.getMonth()];

    return `${day} ${month.substring(0, 3)} ${year} • ${hour}:${minute}`;
  },

  convertToFormatIDNoTime: (date: string, shortMonth?: boolean) => {
    const stringDate = date.split('-');
    return `${stringDate[2].split('T')[0]} ${
      shortMonth
        ? Common.shortMonths[Number(stringDate[1]) - 1]
        : Common.months[Number(stringDate[1]) - 1]
    } ${stringDate[0]}`;
  },

  generateArrayOfYears: () => {
    const max = process.env.APP_CONFIG_ENV === 'development' ? 2050 : new Date().getFullYear();
    const min = max - (max - 1);
    const years = [];

    for (var i = max; i >= min; i--) {
      years.push(i + '');
    }
    return years;
  },

  convertStringToMonth: (value: string) => {
    return Common.months.indexOf(value);
  },

  idrFormatted: (number: number | bigint) => {
    return new Intl.NumberFormat('id-ID').format(number);
  },

  accountNumberFormatter: (value: string) => {
    return value?.replace(/(\d{4})(?=\d)/g, '$1 ');
  },

  padLeft: (str: string, targetLength: number, padString: string) => {
    return str.padStart(targetLength, padString);
  },

  accountNumberUnformatted: (str: string) => {
    const clearValue = str.replaceAll(' ', '');

    return clearValue;
  },

  convertTime: (str: string) => {
    const value = str.split(':');
    return `${value[0]}:${value[1]}`;
  },

  currentDateFormatted: (shortMonth: boolean = false) => {
    const current = new Date();
    const getMonth = () => {
      if (shortMonth) return `${Common.shortMonths[current.getMonth()]}`;

      return `${Common.months[current.getMonth()]}`;
    };

    return `${current.getDate()} ${getMonth()} ${current.getFullYear()}`;
  },

  currentFullTime: () => {
    const current = new Date();
    return `${Utils.padLeft(current.getHours().toString(), 2, '0')}:${Utils.padLeft(
      current.getMinutes().toString(),
      2,
      '0'
    )}:${Utils.padLeft(current.getSeconds().toString(), 2, '0')}`;
  },

  currentDateYyyymmdd: () => {
    const current = new Date();
    return `${Utils.padLeft(current.getFullYear().toString(), 4, '0')}-${Utils.padLeft(
      (current.getMonth() + 1).toString(),
      2,
      '0'
    )}-${Utils.padLeft(current.getDate().toString(), 2, '0')}`;
  },

  convertIso8601: (date: string) => {
    const dateObj = new Date(date);

    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Asia/Jakarta',
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Asia/Jakarta',
      timeZoneName: 'short',
    };

    const formattedDate = dateObj.toLocaleDateString('id-ID', optionsDate);
    const formattedTime = dateObj.toLocaleTimeString('id-ID', optionsTime);

    return `${formattedDate} • ${formattedTime}`;
  },

  formatRFC3339WithLocalTime: (
    date: string,
    isWithTime: boolean,
    isShortMonth: boolean,
    isWithTimeZone = false
  ) => {
    const dateObj = new Date(date);

    const formatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' });
    const parts = formatter.formatToParts(dateObj);

    const timeZonePart = parts.find((part) => part.type === 'timeZoneName');
    let timeZone = '';

    if (timeZonePart?.value) {
      if (timeZonePart?.value === 'GMT+7') {
        timeZone = 'WIB'; // Waktu Indonesia Barat
      } else if (timeZonePart?.value === 'GMT+8') {
        timeZone = 'WITA'; // Waktu Indonesia Tengah
      } else if (timeZonePart?.value === 'GMT+9') {
        timeZone = 'WIT'; // Waktu Indonesia Timur
      }
    }

    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: isShortMonth ? 'short' : 'long',
      year: 'numeric',
      timeZone: 'Asia/Jakarta',
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Asia/Jakarta',
    };

    const formattedDate = dateObj.toLocaleDateString('id-ID', optionsDate);
    const formattedTime = isWithTime
      ? ` • ${dateObj.toLocaleTimeString('id-ID', optionsTime)}`
      : '';

    if (isWithTimeZone) {
      return `${formattedDate}${formattedTime} ${timeZone}`;
    }

    return `${formattedDate}${formattedTime}`;
  },

  prepareMaskedAccountNumber: (value: string): string => {
    const length = value.length;
    if (length >= 10) {
      var maskedNumber: string = '';

      const lastNCharacters: number = length - 4;

      var twoDigits: string = '';

      for (var i = 0; i < lastNCharacters; i++) {
        twoDigits += '*';
      }

      var lastThreeDigits: string = value.substring(lastNCharacters);

      maskedNumber = `${twoDigits}${lastThreeDigits}`;

      return maskedNumber.toString();
    }

    return value;
  },

  spaceAfterWords: (value: string, count: number): string => {
    if (value.length == 0) {
      return value;
    }

    var buffer: string = '';
    for (let i = 0; i < value.length; i++) {
      buffer += value[i];
      const nonZeroIndex = i + 1;
      if (nonZeroIndex % count == 0 && nonZeroIndex != value.length) {
        buffer += ' ';
      }
    }

    return buffer.toString();
  },

  showGenericErrorWithFormat: (error: any, dispatch: Dispatch<AnyAction>) => {
    if (!error.alreadyShowPopup) {
      dispatch(
        setGlobalError({
          isError: true,
          description: `${Common.errorGenericDescription} (${error.response?.data.errors})`,
        })
      );
    }
  },

  typeOfTransactionForShareAndDownload: (method: string): string => {
    switch (method.toUpperCase()) {
      case 'TRANSFER ONLINE':
      case 'INTERNAL':
        return 'ONLINE';
      case 'BIFAST':
        return 'BI FAST';
      case 'BI FAST':
        return 'BI FAST';
      case 'BI-FAST':
        return 'BI FAST';
      case 'SKN':
        return 'SKN';
      case 'RTGS':
        return 'RTGS';
      case 'QRIS':
        return 'QRIS';
      case 'ONLINE_BUSINESS':
        return 'Transfer Online';
      case 'BIFAST_BUSINESS':
        return 'BI-FAST';
      case 'INTERNAL_BUSINESS':
        return 'Transfer Online';
      default:
        return '';
    }
  },

  formatScheduleFrequencyDescription: (frequency: string, date?: number, day?: string): string => {
    switch (frequency) {
      case 'monthly':
        return `Bulanan, Setiap Tanggal ${date}`;
      case 'daily':
        return `Setiap Hari`;
      case 'weekly':
        return `Mingguan, Setiap ${day}`;
      default:
        return frequency;
    }
  },

  randomColor: (index: number) => {
    const randomColors = [
      'bg-blue-raya-50',
      'bg-orange-raya-50',
      'bg-bright-cerulean-50',
      'bg-message-green-60',
      'bg-purple-60',
    ];

    return randomColors[index % randomColors.length];
  },

  formatTime(dateTimeString: any) {
    const date = new Date(dateTimeString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}.${minutes}`;
  },

  convertIso8601WithoutTime: (date: string) => {
    const dateObj = new Date(date);

    const optionsDate: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Asia/Jakarta',
    };

    const formattedDate = dateObj.toLocaleDateString('id-ID', optionsDate);

    return `${formattedDate}`;
  },

  getTodayDateAndDay: () => {
    const today = new Date();
    const dayIndonesia = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    const todayDate = today.getDate();
    const todayDay = dayIndonesia[today.getDay()];

    return { todayDay, todayDate };
  },

  checkStatusBiFastFromMethod: (methodData: UseMethodTransferData[] | undefined) => {
    let isBiFastUnderMaintenance: boolean | undefined = false;
    let biFastMethod: UseMethodTransferData | undefined;

    if (!methodData || methodData.length === 0) {
      return { isBiFastUnderMaintenance, biFastMethod };
    }

    biFastMethod = methodData.find(({ name }) => name?.toUpperCase() === 'BI-FAST');

    isBiFastUnderMaintenance = biFastMethod && biFastMethod.status?.toUpperCase() === 'MAINTENANCE';

    return { isBiFastUnderMaintenance, biFastMethod };
  },

  formatToRp: (value: number) => {
    switch (true) {
      case value >= 1000000:
        return `Rp${(value / 1000000).toFixed(0)} jt`;
      case value >= 1000:
        return `Rp${(value / 1000).toFixed(0)} rb`;
      default:
        return `Rp${value}`;
    }
  },

  calculateDatesRangeQris: (today: Date) => {
    const formatDate = (date: Date): string => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    // Calculating startDate

    const fiveDaysAgo = new Date(today);

    // Ensuring the date does not go below 1
    if (today.getDate() - 4 < 1) {
      fiveDaysAgo.setDate(1);
    } else {
      fiveDaysAgo.setDate(today.getDate() - 4);
    }

    return { startDate: formatDate(fiveDaysAgo), endDate: formatDate(today) };
  },

  generateDateRangeFilter: (
    dateFilterTemp: { months: string; years: string },
    currentDate: Date
  ) => {
    let activeFilter = '';
    let dateRangeFilter = {
      endDate: '',
      startDate: '',
    };

    if (
      dateFilterTemp.months === currentDate.toLocaleString('id-ID', { month: 'long' }) &&
      dateFilterTemp.years === currentDate.toLocaleString('id-ID', { year: 'numeric' })
    ) {
      const { startDate, endDate } = Utils.calculateDatesRangeQris(currentDate);

      activeFilter = `range=${startDate},${endDate}`;
      dateRangeFilter = {
        endDate: endDate.replace(/-/g, '/'),
        startDate: startDate.replace(/-/g, '/'),
      };
    } else {
      const lastDateInMonth = Utils.getLastDayOfMonth(
        Number(dateFilterTemp.years),
        Utils.convertStringToMonth(dateFilterTemp.months)
      );

      const { startDate, endDate } = Utils.calculateDatesRangeQris(
        new Date(lastDateInMonth.split('/').reverse().join('-'))
      );

      activeFilter = `range=${startDate},${endDate}`;
      dateRangeFilter = {
        endDate: endDate.replace(/-/g, '/'),
        startDate: startDate.replace(/-/g, '/'),
      };
    }

    return { activeFilter, dateRangeFilter };
  },

  isBase64Image: (image: string): boolean => {
    const base64Pattern = /^[A-Za-z0-9+/]+={0,2}$/;

    return base64Pattern.test(image) && image.length % 4 === 0;
  },

  getBase64Image: async (imageUrlOrBase64?: string | null): Promise<string> => {
    if (!imageUrlOrBase64) {
      return '';
    }

    if (Utils.isBase64Image(imageUrlOrBase64)) {
      return imageUrlOrBase64;
    }

    try {
      const response = await fetch(imageUrlOrBase64);
      const arrayBuffer = await response.arrayBuffer();
      const base64String = Buffer.from(arrayBuffer).toString('base64');

      return base64String;
    } catch (error) {
      return '';
    }
  },
};
