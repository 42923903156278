import { AxiosRequestConfig } from 'axios';
import client from './client';

/**
A universal fetcher for API requests.
@template RequestPayload - The type of the request payload. Defaults to unknown.
@template ResponseSuccess - The type of the expected response data.
@param url - The endpoint URL for the API request (required).
@param options - Configuration options for the request (optional).
@param options.method - The HTTP method to use (e.g., 'GET', 'POST', 'PUT', 'DELETE'). Defaults to 'GET'.
@param options.payload - The request payload to include (for methods like POST or PUT). Optional.
@param options.config - Additional Axios configuration (e.g., headers, query parameters). Optional.
@returns A promise that resolves to the response data.
*/
async function fetcher<RequestPayload, ResponseSuccess>(
  url: string,
  options?: {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    payload?: RequestPayload;
    config?: AxiosRequestConfig;
  }
): Promise<ResponseSuccess> {
  const response = await client.request<ResponseSuccess>({
    url,
    method: options?.method || 'GET',
    data: options?.payload,
    ...options?.config,
  });

  return response.data;
}

export default fetcher;
