const Endpoint = {
  detailMediaInfoBanner: '/api/webview/media/detail/',
  dataInquiryCustomer: '/tarik-tunai/webview/',
};

export const EndpointPartner = {
  setPinPassword: '/pinang/set-pin-password/',
};

// Saku Anak
export const EndpointSakuAnak = {
  // Auth
  authLogin: '/v1/saku-youth/auth/login',

  // Inquiry
  dashboard: '/v1/saku-youth/user/dashboard',

  // Detail Saku
  sakuMutations: '/v2/saku-youth/statement',
  detailTransaction: '/v1/saku-youth/trx/detail',
};

// Static Endpoint
export const EndpointStatic = {
  provinces: '/saku-bisnis/area/provinces',
  cities: '/saku-bisnis/area/provinces/%PROVINCE_ID%/cities',
  districts: '/saku-bisnis/area/cities/%CITY_ID%/districts',
  subdistrict: '/saku-bisnis/area/districts/%DISTRICT_ID%/subdistricts',
  searchAddress: '/saku-bisnis/area/search?',
};

export const EndpointLotteryRaya = {
  home: '/v2/lottery',
  history: '/v2/lottery/history',
};

export const EndpointBannerEmailVerification = {
  checkAttemptSendEmail: '/send-email-attempt',
  verifyEmail: '/send-verify-email',
  getProfile: '/v2/users/profile',
};

// Saku Bisnis
export const EndpointSakuBisnis = {
  // Auth
  auth: '/saku-bisnis/auth/login',
  refreshToken: '/saku-bisnis/auth/refresh',
  newRegistration: '/saku-bisnis/register',
  ping: '/saku-bisnis/auth/ping',

  // Saku
  saku: '/saku-bisnis/saku',
  bySakuId: '/saku-bisnis/saku/%SAKU_ID%',
  sakuMetaData: '/saku-bisnis/saku/metadata',
  moveSakuBalance: '/saku-bisnis/saku/move-balance',
  addSakuBalance: '/saku-bisnis/saku/add-balance',
  listSakuStatement: '/saku-bisnis/saku/statement',
  sakuMutations: '/saku-bisnis/v1/statement-with-balance',

  //Mass Transfer
  massTransferInquiry: '/saku-bisnis/transfer/mass/inquiry',
  massTransferPayment: '/saku-bisnis/transfer/mass/payment',
  massTransferCheckStatus: '/saku-bisnis/transfer/mass/status',
  massTransferLimit: '/saku-bisnis/transfer/mass/transaction-limit',

  // User
  inquiryUser: '/v2/saku-bisnis/user',
  deviceInformation: '/saku-bisnis/user/device-info',
  verifyPin: '/saku-bisnis/pin/verify',
  businessAccount: '/saku-bisnis/v1/user/business-profile/rename',

  // Transactional
  detailTransaction: '/saku-bisnis/transactions/detail',
  historyTransfer: '/saku-bisnis/transaction/history',

  // Contact
  contactFavorite: '/saku-bisnis/contact/favorite',
  byContactId: '/saku-bisnis/contact/favorite/%CONTACT_ID%',

  // Transfer Needs
  methodList: '/saku-bisnis/transfer/methods',
  bankList: '/saku-bisnis/transfer/banks',

  // Internal Transfer
  internalPayment: '/saku-bisnis/transfer/internal/payment',
  internalInquiry: '/saku-bisnis/transfer/internal/inquiry',
  accountNumberInternalCheck: '/saku-bisnis/transfer/internal/check-destination',

  // External Transfer
  externalPayment: '/saku-bisnis/transfer/external/payment',
  externalInquiry: '/saku-bisnis/transfer/external/inquiry',
  accountNumberExternalCheck: '/saku-bisnis/transfer/external/check-destination',
  externalBiFastInquiry: '/saku-bisnis/transfer/bifast/inquiry',
  externalBiFastPayment: '/saku-bisnis/transfer/bifast/payment',
  externalBiFastDetail: '/saku-bisnis/transfer/bifast/detail',

  // Schedule Transfer
  scheduleTransfer: '/saku-bisnis/transfer/schedules',
  scheduleTransferUpdateStatus: '/saku-bisnis/transfer/schedules/status',

  //  Qris Activation
  qrisStart: '/saku-bisnis/qris/register/start',
  qrisDetail: '/saku-bisnis/qris/register/info/%QRISACTIVATION_ID%',
  qrisStatus: '/saku-bisnis/qris/status',
  qrisAddressByKtp: '/saku-bisnis/qris/ktp-address',
  qrisGenerateStaticById: '/saku-bisnis/qris/static/url/%QRIS_STATIC_ID%',
  qrisCategory: '/business-categories',
  qrisDetailData: '/saku-bisnis/qris/detail/%QRISACTIVATION_ID%',
  qrisRegister: '/saku-bisnis/qris/register',
  qrisRegisterFinish: '/qris/register/%QRISACTIVATION_ID%', //unused
  qrisActivationFinish: '/saku-bisnis/qris/register/finish/%QRISACTIVATION_ID%',

  // Qris
  qrisTransactionHistory: '/saku-bisnis/qris/%TRANSACTION_FILTER%',
  qrisBalanceToday: '/saku-bisnis/qris/%QRISACTIVATION_ID%/balance', //same with line 118
  qristransactionHistoryDetail: '/qris/%TRANSACTION_FILTER%/transaction-history', //unused
  qrisBalanceInfo: '/saku-bisnis/qris/%QRISACTIVATION_ID%/balance',
  qrisHistoryTransaction: '/saku-bisnis/qris/%QRISACTIVATION_ID%/transaction-history',
  qrisGenerateDynamicById: '/saku-bisnis/qris/dynamic/url/%QRIS_DYNAMIC_ID%',

  //  Employee Access (EA)
  EA: '/saku-bisnis/employees',
  EAAvailableRole: '/saku-bisnis/employees/invitations/role-permissions',
  EAInviteValidation: '/saku-bisnis/employees/check',
  EACancelEmployeeInvitation: '/saku-bisnis/employees/invitations/cancel',
  EARevokeEmployeeAccess: '/saku-bisnis/employees/revoke',
  EARevokeReasons: '/saku-bisnis/employees/revoke/reasons',
  EAInvite: '/saku-bisnis/employees/invite',
  EAInquiryAccessPermissions: '/saku-bisnis/employees/access/inquiry',
  EADetailEmployee: '/v2/saku-bisnis/employees/invitations/detail',

  // Event Tracker
  eventTracker: '/saku-bisnis/event-tracker',
};

export default Endpoint;
