import { useMutation } from '@tanstack/react-query';
import { ActivityTrackerContext, TrackerPayload } from 'context/userActivityTrackerContext';
import { EndpointSakuBisnis } from 'data/constants/endpoint';
import fetcher from 'lib/fetcher';
import React, { PropsWithChildren, useCallback } from 'react';

interface UserActivityTrackerProviderProps extends PropsWithChildren {}

const UserActivityTrackerProvider: React.FC<UserActivityTrackerProviderProps> = ({ children }) => {
  const mutation = useMutation({
    mutationKey: ['eventTracker'],
    mutationFn: async (payload: TrackerPayload) =>
      await fetcher<TrackerPayload, undefined>(EndpointSakuBisnis.eventTracker, {
        method: 'POST',
        payload,
      }),
    onError: (error) => {
      console.error('Tracking failed:', error);
    },
  });

  const trackEvent = useCallback((payload: TrackerPayload) => mutation.mutate(payload), []);

  return (
    <ActivityTrackerContext.Provider value={trackEvent}>{children}</ActivityTrackerContext.Provider>
  );
};

export default UserActivityTrackerProvider;
