import { Modal } from 'antd';
import Lottie from 'lottie-react';
import { useAppSelector } from 'redux/store';
import SpinnerLoader from '../../public/assets/icons/lottie/spinner.json';

interface LoadingDialogProps {
  loading: boolean;
}

export default function LoadingDialog({ loading }: LoadingDialogProps) {
  const isLoadingLottery = useAppSelector((state) => state.app.isLoadingLottery);
  return (
    <div className="constraint-width mx-auto h-full overflow-y-auto bg-raya-lottery">
      <Modal
        open={isLoadingLottery || loading}
        footer={null}
        closable={false}
        width={118}
        className="modal-loading"
        centered
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex min-w-[24px] max-w-[24px] min-h-[76px] max-h-[76px] justify-center items-center mb-2">
            <div className="relative w-[24px] h-[76px] mx-auto block">
              <Lottie animationData={SpinnerLoader} width={'100%'} className="h-24 " />
            </div>
          </div>
          <p className="text-[10px] text-secondary">Mohon tunggu, ya..</p>
        </div>
      </Modal>
    </div>
  );
}
