import axios, { CreateAxiosDefaults } from 'axios';
import { setupInterceptorsTo } from '../services/api_interceptor';

const defaultHeader: CreateAxiosDefaults = {
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false,
};

export const instance = axios.create(defaultHeader);

const client = setupInterceptorsTo(instance);

export default client;
