import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import Endpoint, {
  EndpointBannerEmailVerification,
  EndpointLotteryRaya,
  EndpointPartner,
  EndpointSakuAnak,
  EndpointSakuBisnis,
  EndpointStatic,
} from 'data/constants/endpoint';
import { LocalStorageKeys } from 'data/constants/localStorageKeys';
import { SessionStorageKeys } from 'data/constants/sessionStorageKeys';
import SentryError from 'types/sentryError';
import LocalStorageEncrypter from 'utils/localStorageEncrypted';

// On Request

const setXPinToken = (config: AxiosRequestConfig) => {
  if (
    config.url?.includes(EndpointSakuBisnis.internalPayment) ||
    config.url?.includes(EndpointSakuBisnis.externalPayment) ||
    config.url?.includes(EndpointSakuBisnis.moveSakuBalance) ||
    config.url?.includes(EndpointSakuBisnis.addSakuBalance) ||
    config.url?.includes(EndpointSakuBisnis.qrisRegister) ||
    config.url?.includes(EndpointSakuBisnis.EAInvite) ||
    config.url?.includes(
      EndpointSakuBisnis.qrisActivationFinish.replaceAll('%QRISACTIVATION_ID%', '')
    ) ||
    config.url?.includes(EndpointSakuBisnis.EACancelEmployeeInvitation) ||
    config.url?.includes(EndpointSakuBisnis.EARevokeEmployeeAccess)
  ) {
    const xPinToken = sessionStorage.getItem(SessionStorageKeys.xPinToken);

    config.headers = {
      ...config.headers,
      'X-PIN-TOKEN': xPinToken,
    };

    sessionStorage.removeItem(SessionStorageKeys.xPinToken);
  }
};

const getAccessToken = (config: AxiosRequestConfig) => {
  if (
    config.url?.includes(EndpointSakuBisnis.internalPayment) ||
    config.url?.includes(EndpointSakuBisnis.externalPayment) ||
    config.url?.includes(EndpointSakuBisnis.EADetailEmployee) ||
    config.url?.includes(EndpointSakuAnak.detailTransaction) ||
    config.url?.includes(EndpointSakuAnak.sakuMutations) ||
    config.url?.includes(EndpointLotteryRaya.home) ||
    config.url?.includes(EndpointBannerEmailVerification.checkAttemptSendEmail) ||
    config.url?.includes(EndpointBannerEmailVerification.verifyEmail) ||
    config.url?.includes(EndpointBannerEmailVerification.getProfile) ||
    config.url?.includes(EndpointSakuBisnis.eventTracker)
  ) {
    return LocalStorageEncrypter.getItem(LocalStorageKeys.rayaToken);
  } else {
    return LocalStorageEncrypter.getItem(LocalStorageKeys.accessToken);
  }
};

const actionToPreventContentTypeCors = (config: AxiosRequestConfig) => {
  const isScheduleTransferRequest = (method: string) =>
    config.url?.includes(EndpointSakuBisnis.scheduleTransfer) &&
    config?.method?.toUpperCase() === method;

  if (
    config.url?.includes(EndpointSakuBisnis.bankList) ||
    config.url?.includes(EndpointSakuBisnis.methodList) ||
    config.url?.includes(EndpointSakuBisnis.massTransferCheckStatus) ||
    config.url?.includes(EndpointLotteryRaya.home) ||
    config.url?.includes(EndpointLotteryRaya.history) ||
    config.url?.includes(EndpointBannerEmailVerification.checkAttemptSendEmail) ||
    config.url?.includes(EndpointBannerEmailVerification.verifyEmail) ||
    config.url?.includes(EndpointBannerEmailVerification.getProfile) ||
    ['GET', 'DELETE'].some(isScheduleTransferRequest)
  ) {
    config.data = {};
  }
};

const actionForAllTarikTunaiRequest = (config: AxiosRequestConfig) => {
  if (process.env.APP_CONFIG_ENV === 'development') {
    config.baseURL = 'https://api-tartun.rcs.dev.rayain.net';
  } else {
    config.baseURL = undefined;
  }
};

const actionForSakuAnakRequest = (config: AxiosRequestConfig) => {
  if (process.env.APP_CONFIG_ENV === 'stage') {
    config.baseURL = `${process.env.WEBVIEW_BASE_URL}/stg`;
  } else {
    // Dev and Prod
    config.baseURL = process.env.WEBVIEW_BASE_URL;
  }
};

const actionForBannerEmailVerificationRequest = (config: AxiosRequestConfig) => {
  if (config.url?.includes(EndpointBannerEmailVerification.checkAttemptSendEmail)) {
    config.url = `v2/users${EndpointBannerEmailVerification.checkAttemptSendEmail}`;
  } else if (config.url?.includes(EndpointBannerEmailVerification.verifyEmail)) {
    config.url = `v2/users${EndpointBannerEmailVerification.verifyEmail}`;
  }
};

const onRequest = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
  if (config.url?.includes(Endpoint.detailMediaInfoBanner)) {
    config.baseURL = process.env.NEXT_PUBLIC_BASE_URL_CMS;
  } else if (config.url?.includes(Endpoint.dataInquiryCustomer)) {
    actionForAllTarikTunaiRequest(config);
  } else if (
    // Saku Anak
    config.url?.includes(EndpointSakuAnak.authLogin) ||
    config.url?.includes(EndpointSakuAnak.dashboard) ||
    config.url?.includes(EndpointSakuAnak.detailTransaction) ||
    config.url?.includes(EndpointSakuAnak.sakuMutations)
  ) {
    actionForSakuAnakRequest(config);

    const accessToken = getAccessToken(config);
    config.headers['Authorization'] = accessToken;
  } else if (
    // Registration
    config.url?.includes(EndpointSakuBisnis.newRegistration) ||
    // Auth
    config.url?.includes(EndpointSakuBisnis.auth) ||
    config.url?.includes(EndpointSakuBisnis.refreshToken) ||
    config.url?.includes(EndpointSakuBisnis.ping) ||
    config.url?.includes(EndpointSakuBisnis.verifyPin) ||
    // Saku
    config.url?.includes(EndpointSakuBisnis.sakuMetaData) ||
    config.url?.includes(EndpointSakuBisnis.saku) ||
    config.url?.includes(EndpointSakuBisnis.bySakuId.replaceAll('%SAKU_ID%', '')) ||
    config.url?.includes(EndpointSakuBisnis.moveSakuBalance) ||
    config.url?.includes(EndpointSakuBisnis.addSakuBalance) ||
    config.url?.includes(EndpointSakuBisnis.listSakuStatement) ||
    config.url?.includes(EndpointSakuBisnis.sakuMutations) ||
    config.url?.includes(EndpointSakuBisnis.businessAccount) ||
    // Transactional
    config.url?.includes(EndpointSakuBisnis.historyTransfer) ||
    config.url?.includes(EndpointSakuBisnis.detailTransaction) ||
    config.url?.includes(EndpointSakuBisnis.methodList) ||
    config.url?.includes(EndpointSakuBisnis.bankList) ||
    // Internal Transfer
    config.url?.includes(EndpointSakuBisnis.internalPayment) ||
    config.url?.includes(EndpointSakuBisnis.internalInquiry) ||
    config.url?.includes(EndpointSakuBisnis.accountNumberInternalCheck) ||
    // External Transfer
    config.url?.includes(EndpointSakuBisnis.externalPayment) ||
    config.url?.includes(EndpointSakuBisnis.externalInquiry) ||
    config.url?.includes(EndpointSakuBisnis.accountNumberExternalCheck) ||
    config.url?.includes(EndpointSakuBisnis.externalBiFastInquiry) ||
    config.url?.includes(EndpointSakuBisnis.externalBiFastPayment) ||
    config.url?.includes(EndpointSakuBisnis.externalBiFastDetail) ||
    // schedule Transfer
    config.url?.includes(EndpointSakuBisnis.scheduleTransfer) ||
    // User
    config.url?.includes(EndpointSakuBisnis.inquiryUser) ||
    config.url?.includes(EndpointSakuBisnis.deviceInformation) ||
    // Mass
    config.url?.includes(EndpointSakuBisnis.massTransferInquiry) ||
    config.url?.includes(EndpointSakuBisnis.massTransferPayment) ||
    config.url?.includes(EndpointSakuBisnis.massTransferCheckStatus) ||
    config.url?.includes(EndpointSakuBisnis.massTransferLimit) ||
    // Contact
    config.url?.includes(EndpointSakuBisnis.contactFavorite) ||
    config.url?.includes(EndpointSakuBisnis.byContactId.replaceAll('%CONTACT_ID%', '')) ||
    // Qris Activation
    config.url?.includes(
      EndpointSakuBisnis.qrisGenerateStaticById.replaceAll('%QRIS_STATIC_ID%', '')
    ) ||
    config.url?.includes(EndpointSakuBisnis.qrisRegister) ||
    config.url?.includes(EndpointSakuBisnis.qrisStatus) ||
    config.url?.includes(
      EndpointSakuBisnis.qrisTransactionHistory.replaceAll('%TRANSACTION_FILTER%', '')
    ) ||
    config.url?.includes(EndpointSakuBisnis.qrisAddressByKtp) ||
    config.url?.includes(EndpointSakuBisnis.qrisDetailData.replaceAll('%QRISACTIVATION_ID%', '')) ||
    config.url?.includes(
      EndpointSakuBisnis.qrisActivationFinish.replaceAll('%QRISACTIVATION_ID%', '')
    ) ||
    config.url?.includes(
      EndpointSakuBisnis.qrisBalanceInfo.replaceAll('%QRISACTIVATION_ID%', '')
    ) ||
    config.url?.includes(
      EndpointSakuBisnis.qrisHistoryTransaction.replaceAll('%QRISACTIVATION_ID%', '202') ||
        EndpointSakuBisnis.qrisGenerateDynamicById.replaceAll('%QRIS_DYNAMIC_ID%', '')
    ) ||
    config.url?.includes(EndpointStatic.provinces) ||
    config.url?.includes(EndpointStatic.cities.replaceAll('%PROVINCE_ID%/cities', '')) ||
    config.url?.includes(EndpointStatic.districts.replaceAll('%CITY_ID%/districts', '')) ||
    config.url?.includes(EndpointStatic.subdistrict.replaceAll('%DISTRICT_ID%/subdistricts', '')) ||
    config.url?.includes(EndpointStatic.searchAddress) ||
    (config.url?.includes(EndpointSakuBisnis.EA) &&
      !config.url?.includes(EndpointSakuBisnis.EADetailEmployee))
  ) {
    let accessToken;

    if (!config.url?.includes(EndpointSakuBisnis.auth)) {
      accessToken = getAccessToken(config);
    }

    config.headers['Content-Type'] = 'application/json';
    config.headers['Authorization'] = accessToken;

    setXPinToken(config);

    actionToPreventContentTypeCors(config);

    config.baseURL = process.env.WEBVIEW_BASE_URL;
  } else if (
    config.url?.includes(EndpointLotteryRaya.home) ||
    config.url?.includes(EndpointLotteryRaya.history) ||
    config.url?.includes(EndpointSakuBisnis.EADetailEmployee) ||
    config.url?.includes(EndpointBannerEmailVerification.checkAttemptSendEmail) ||
    config.url?.includes(EndpointBannerEmailVerification.verifyEmail) ||
    config.url?.includes(EndpointBannerEmailVerification.getProfile) ||
    config.url?.includes(EndpointSakuBisnis.eventTracker)
  ) {
    let accessToken;
    accessToken = getAccessToken(config);
    actionForBannerEmailVerificationRequest(config);
    config.headers['Content-Type'] = 'application/json';
    config.headers['Authorization'] = accessToken;

    actionToPreventContentTypeCors(config);

    config.baseURL = process.env.NEXT_PUBLIC_BASE_URL_GATEWAY_RAYA_LOTTERY;
  } else if (config.url?.includes(EndpointPartner.setPinPassword)) {
    config.baseURL = process.env.NEXT_PUBLIC_BASE_URL_GATEWAY_PARTNER;
  } else if (config.url?.includes(EndpointSakuBisnis.qrisCategory)) {
    config.baseURL = process.env.NEXT_PUBLIC_BASE_URL_DATA_MASTER_STATIC;
  }

  return config;
};

// On Request Error

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  let sentryError = new SentryError({
    name: 'API Request Error',
    message: 'No response received. Got an error when requesting to the server',
    cause: error,
  });
  sentryError.captureException();

  return Promise.reject(error);
};

// On Response Success

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

// On Response Error

function actionSendToSentry(error: AxiosError) {
  const currentPath: string = window.location.pathname;

  let sentryError = new SentryError({
    name: `API Res Err ${currentPath} ${error.config?.url}`,
    message: `${(error.response?.data as any)?.status ?? '"status" not found'} - ${
      (error.response?.data as any)?.errors?.toString() ?? '"errors" not found'
    }`,
    cause: error,
  });

  const isTokenUnauthorized: boolean =
    error.status === 401 && (error.response?.data as any)?.status === 'UNAUTHORIZED';

  const isTokenExpired: boolean =
    error.config?.url === EndpointSakuBisnis.refreshToken &&
    error.status === 500 &&
    (error.response?.data as any)?.errors.toString().includes('token is expired');

  const trrefnNotFound: boolean =
    error.config?.url?.includes(EndpointSakuBisnis.detailTransaction) &&
    (error.response?.data as any)?.message.toString().includes('not found in database');

  // TODO(Naufal): Please add inquiry external account
  const accountNumberInquiryNotFound: boolean =
    error.config?.url === EndpointSakuBisnis.accountNumberInternalCheck &&
    (error.response?.data as any)?.code === '98';

  const networkError = error.code === 'ERR_NETWORK';

  if (
    !isTokenUnauthorized &&
    !isTokenExpired &&
    !trrefnNotFound &&
    !accountNumberInquiryNotFound &&
    !networkError
  ) {
    sentryError.captureException();
  }
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  actionSendToSentry(error);

  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
