// Saku Bisnis
export const LocalStorageKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  rayaToken: 'rayaToken',
  isAlreadyIntro: 'isAlreadyIntro',
  isAlreadyIntroMassTransfer: 'isAlreadyIntroMassTransfer',
  isAlreadyIntroQris: 'isAlreadyIntroQris',
  businessProfileId: 'businessProfileId',
  isAlreadyIntroAccount: 'isAlreadyIntroAccount',
  isHiddenBannerEA: 'isHiddenBannerEA',
};
