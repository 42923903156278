import { createContext } from 'react';

type CategoryTypes = 'AKTIVASI_SAKU_BISNIS' | 'BERANDA_SAKU_BISNIS';

export interface TrackerPayload {
  category: CategoryTypes;
  eventName: string;
  properties: Record<string, any>;
}

// eslint-disable-next-line no-unused-vars
type ActivityTrackerContextType = (payload: TrackerPayload) => void;

export const ActivityTrackerContext = createContext<ActivityTrackerContextType>(() => {});
