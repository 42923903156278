export const SessionStorageKeys = {
  transferFromContactHistory: 'transferFromContactHistory',
  transferProccessIsDone: 'transferProccessIsDone',
  idPartner: 'idPartner',
  secureDataPartner: 'secureDataPartner',
  tokenPartner: 'tokenPartner',
  xPinToken: 'xPinToken',
  massTransfer: 'massTransfer',
  transferSingleData: 'transferSingleData',
  previousRouteOnPop: 'previousRouteOnPop',
  developmentHasAccount: 'development:hasAccount',
  scrollPosition: 'scrollPosition',
  qrisAddressByIdcard: 'qrisAddressByIdcard',
  user: 'user'
};
