import CryptoJS from 'crypto-js';

const setItem = (key: string, value: string) => {
  const valueEncrypted = CryptoJS.AES.encrypt(JSON.stringify({ value: value }), '6C71bucmKu');
  localStorage.setItem(key, valueEncrypted.toString());
};

const getItem = (key: string): string | null => {
  const value = localStorage.getItem(key);

  if (value === null) return value;

  const bytes = CryptoJS.AES.decrypt(value, '6C71bucmKu');
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);

  return JSON.parse(decrypted).value;
};

const LocalStorageEncrypter = {
  setItem: setItem,
  getItem: getItem,
};

export default LocalStorageEncrypter;
